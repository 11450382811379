* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  /* font-family: 'Vazir', sans-serif; */
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: #f7f8f9;
  /*  todo this backgroundColor value not in the variable  */
  box-sizing: border-box;
}

/* .mainWrapper {
	height: calc(100vh - 80px);
}
.titleWrapper {
	height: 80px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #e2e2ea;
	padding: 0 30px;
}
.headTitle {
	font-size: 24px;
	font-weight: 600;
	margin: 0;
}
.bodyContainer{
	padding: 30px 30px 100px;
	height: calc(100% - 80px);
	position: relative;
}
.centerPagination {
	display: flex;
	position: fixed;
	bottom: 0;
	z-index: 1;
	width: 100%;
	background-color: white;
	box-shadow: 0 0 10px -5px gray;
	right: 0;
	justify-content: center;
	align-items: center;
	padding: 10px;
}
.modalTitle {
	font-size: 24px;
	margin: 5px 0;
	font-weight: bold;
}
.contentContainer {
	padding: 20px;
	display: flex;
	flex-direction: column;
	background: #F8F8F8;
	width: 100%;
	height: calc(100% - 60px);
}
.emptyTable {
	height: 150px;
	width: 150px;
	margin-top: 15vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: "center";
	position: absolute;
	top: calc(50% - 75px);
	right: calc(50% - 75px);
}
.PROCESS, .IN_PROCESS {
	background: rgb(182, 182, 255);
}
.OVERDUE {
	background: rgb(255, 153, 159);
}
.DONE {
	background: rgb(131, 229, 131);
}
.CANCELED {
	background: #aeadad;
} */
